import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import appFilters from '@/filters';
import { DOCUMENTS_STORE } from '@/store/modules/documents';
import { formatBytes, } from '../../../helpers/file.helper';
export default defineComponent({
    name: 'current-members-plan-modal',
    emits: ['close'],
    components: {
        BaseModal,
    },
    data() { },
    props: {
        show: Boolean,
    },
    computed: {
        ...mapGetters(DOCUMENTS_STORE, ['openDetailsModal', 'selectedDocument']),
        document() {
            const { selectedDocument } = this;
            const { isDir } = selectedDocument;
            return {
                isDir,
                type: isDir ? 'Folder' : 'File',
                name: isDir ? selectedDocument.name : selectedDocument.originalName,
                owner: 'Me',
                updatedAt: appFilters.formatToDate(selectedDocument.dateUpdated, 'DD MMM YYYY'),
                fileCount: '--',
                fileSize: isDir ? '--' : formatBytes(selectedDocument.fileSize),
            };
        }
    },
    methods: {
        ...mapActions(DOCUMENTS_STORE, ['setOpenDetailsModal']),
        cancel() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('close');
        },
    }
});
