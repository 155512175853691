<template>
    <div class="flex-column">
        <!-- <el-button type="primary" class="button-cta" round disabled v-if="getPaymentMethod === ''">PAY</el-button>
        <el-button type="primary" class="button-cta active flex-row ai-c jc-c" @click="routeToPropertyDashboard" round v-else>
            BUY WITH
            <inline-svg :src="require(`@/assets/icons/a-payment-white.svg`)" v-if="getPaymentMethod === 'apple-pay'"></inline-svg>
            <inline-svg :src="require(`@/assets/icons/g-payment.svg`)" v-else></inline-svg>
        </el-button> -->

        <el-button
        type="primary"
        class="button-cta"
        :class="{ 'yellow-button': isReadyToPay }"
        @click="onPay()"
        :loading="isLoading"
        :disabled="isLoading || !isReadyToPay" round>{{ isLoading ? 'PROCESSING...' : buttonText ? buttonText : 'PAY' }}</el-button>

    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import { BILLING_STORE } from '@/store/modules/billing';

export default defineComponent({
  computed: {
    ...mapGetters(BILLING_STORE, ['getPaymentMethod', 'getStoragePlan']),

    isReadyToPay() {
      return (this.getPaymentMethod !== '' && this.getStoragePlan.planType !== null) || this.manuallySetReadyToPay;
    }
  },

  props: ['buttonText', 'manuallySetReadyToPay'],

  emits: ['on-pay'],

  data() {
    return {
      isLoading: false
    };
  },

  methods: {
    onPay() {
      this.isLoading = true;

      setTimeout(() => {
        this.$emit('on-pay');
      }, 500);
    }
  },
});
</script>
<style lang="scss" scoped>
.button-cta {
    height: 42px;
    border-radius: 8px;
    background: rgba(12, 15, 74, 0.1);
    font-family: Mulish;
    font-size: 15px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
    color: rgba(12, 15, 74, 0.5);
    font-family: Mulish;
    border: none;
    text-transform: uppercase;

    &:hover {
        background: rgba(12, 15, 74, 0.1);
        color: rgba(12, 15, 74, 0.5);
    }
}
.active {
    background: #000000;
    color: #FFFFFF;

    :deep(span) {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .7rem;
    }

    &:hover {
        background: #000000;
        color: #FFFFFF;
    }
}

.button-cta.yellow-button {
  width: 100%;
  border-radius: 7px;
  background: #FAA200;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  letter-spacing: 1.25px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
}
</style>
