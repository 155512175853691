<template>
    <div class="current-plan-container flex-row jc-sb ai-c">
        <div class="current-plan-container__label-container flex-row ai-c">
            <!-- <p class="current-plan-container__label default-text">Current monthly plan - <span class="accent">{{ subscription.capacity }}</span> <span class="secondary">(0.9GB of 1GB used)</span> </p> -->
            <p class="current-plan-container__label default-text">Current monthly plan - <span class="accent">{{ subscription.capacity }}</span></p>
        </div>
        <p class="current-plan-container__billing-amount default-text">
            £{{ subscription.monthly }}
        </p>
    </div>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';

export default defineComponent({
  props: ['subscription']
});
</script>
<style lang="scss" scoped>
.default-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: left;
}
.current-plan-container {
    &__label {
        .accent {
            color: #FFA500;
        }
        .secondary {
            font-size: 12px;
            font-weight: 600;
            line-height: 25px;
            letter-spacing: 0.25px;
            color: rgba(12, 15, 74, 0.5);
        }
    }
}
</style>
