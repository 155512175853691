<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="cancel"
  :hasActions="false"
  customClass="xs-modal"
>
  <div class="modal-content flex-column jc-sb">
    <div class="flex-column jc-sb">
        <div class="modal-content__modal-header flex-row jc-fe ai-c">
            <!-- <h2 class="modal-header__title default-text">Current plan</h2> -->
            <div class="modal-content__header-icon">
              <inline-svg @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <div class="modal-content__content flex-column">
          <p class="modal-content__title default-text">File upload</p>
          <el-divider/>
          <div class="modal-content__input-container flex-column">
            <!-- <p class="modal-content__input-name default-text">{{ openedFolder?.name || '' }}</p> -->
          </div>
        </div>
        <UploadForm
          v-if="openNewFileUploadModal"
          :record="form"
          :attachmentSources="[]"
          :useCustomUploadIcon="true"
          :allowMultiple="true"
          />
    </div>
    <div class="modal-content__actions grid">
      <el-button @click="onSave" class="button" :class="{'active': hasAttachments }" :disabled="!hasAttachments || isUploading">{{ isUploading ? 'Saving..' : 'Save' }}</el-button>
      <el-button @click="cancel" class="button">CANCEL</el-button>
    </div>
  </div>
</BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import UploadForm from '@/core/components/common/forms/upload/Upload.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { DOCUMENTS_STORE } from '@/store/modules/documents';

export default defineComponent({
  name: 'file-upload-modal',
  emits: ['close'],
  components: {
    BaseModal,
    UploadForm
  },
  props: {
    show: Boolean,
  },

  data() {
    return {
      isUploading: false,
      form: {
        attachments: [],
      }
    };
  },

  created() {
    this.form.attachments = [];
  },

  computed: {
    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    ...mapGetters(DOCUMENTS_STORE, [
      'openNewFileUploadModal',

      'openedFolder',
      'fileList',
      'folderList'
    ]),
    hasAttachments() {
      return this.form.attachments.length > 0;
    }
  },

  methods: {
    ...mapActions(DOCUMENTS_STORE, [
      'setOpenNewFileUploadModal',

      'createUserDocument',
      'getUserDocuments',
      'setFileList',
      'setDocumentList'
    ]),

    cancel() {
      this.form.attachments = [];
      if (!this.isUploading) {
        this.$emit('close');
      }
    },

    async onSave() {
      const { params: routeParams, name } = this.$route;
      const { attachments } = this.form;
      const { id } = this.openedFolder;
      const parentId = id || null;
      const { userId } = this.authenticatedUser;
      const propertyEntity = name.includes('property-details') && routeParams.id ? { propertyId: routeParams.id } : '';
      const params = {
        userId,
        formData: {
          name: '--',
          parentId,
          attachments,
          entity: propertyEntity ? JSON.stringify(propertyEntity) : ''
        }
      };
      let docParams = {};
      if ('id' in this.openedFolder) {
        docParams = { parentId: id };
      }

      this.isUploading = true;

      await this.createUserDocument(params)
        .then(async () => {
          await this.getFolderList(docParams);
        })
        .catch(() => {
          this.$notify.error({
            message: 'Error upload file(s).'
          });
        })
        .finally(() => {
          this.isUploading = false;
          this.cancel();
        });
    },

    async getFolderList(options = {}) {
      const { userId } = this.authenticatedUser;
      const params = { userId };
      const { parentId } = options;
      const hasParentParam = parentId || null;

      if (Object.keys(options).length) {
        params.options = options;
      }

      await this.getUserDocuments(params)
        .then((userDocuments) => {
          let fileList = userDocuments.filter((userDocument) => !userDocument.isDir
            && userDocument.parentId === hasParentParam);
          fileList = fileList.map((file) => {
            return { ...file, name: file.originalName };
          });

          this.setFileList(fileList);
          this.setDocumentList([...this.folderList, ...fileList]);
        });
    },
  }
});
</script>
<style lang="scss" scoped>
.default-text {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15000000596046448px;
    margin: 0;
    color: #0C0F4A;
}
.modal-content {
  &__title {
    font-size: 24px;
    text-align: center;
  }
  &__input-container {
    gap: .1rem;
  }
  &__input-name {
    font-size: 12px;
    line-height: 22px;
  }
  &__text-description {
    font-size: 12px;
    font-weight: 500;
    color: rgba(12, 15, 74, 0.5);
  }
  &__actions {
    width: 100%;
    grid-template-columns: 1fr 1fr;
  }
}
.button {
    height: 40px;
    padding: 0 2rem;
    box-sizing: border-box;
    background-color: rgba(12, 15, 74, 0.05);
    border: none;
    border-radius: 7px;
    @extend .default-text;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: rgba(12, 15, 74, 0.5);

    &:hover {
        background-color: rgba(12, 15, 74, 0.05);
        color: rgba(12, 15, 74, 0.5);
    }
}
.active {
    background-color: #FFA500;
    color: #fff;

    &:hover {
        background-color: #FFA500;
        color: #fff;
    }
}
:deep(.el-input__inner) {
  border-radius: 8px;
}
.el-divider {
  margin: 0;
}
</style>
