<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="cancel"
  :hasActions="false"
  customClass="large-modal"
>
  <div class="modal-content flex-column jc-sb">
    <div class="flex-column jc-sb">
        <div class="modal-content__modal-header flex-row jc-sb ai-c">
            <h2 class="modal-content__header-title default-text">Storage</h2>
            <div class="modal-content__header-icon cursor-pointer">
              <inline-svg @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <div class="modal-content__content flex-column">
            <div class="modal-content__row">
              <CurrentPlanDetails :subscription="subscription"/>
            </div>
            <div class="modal-content__row">
              <BillingStorageTypeComponent :plan="selectedPlan" @on-select-plan="onSelectPlan"/>
            </div>
            <!-- <BillingPaymentMethodComponent/> -->
            <NewStorageSummaryBilling :plan="selectedPlan"/>
        </div>
    </div>
    <div class="modal-content__summary-container flex-column">
      <PaymentButtonComponent @on-pay="onPay"/>
    </div>
  </div>
</BaseModal>
</template>
<script>
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import CurrentPlanDetails from '@/core/components/billing/CurrentPlanDetails.vue';
import NewStorageSummaryBilling from '@/core/components/billing/NewStorageSummaryBilling.vue';
import PaymentButtonComponent from '@/core/components/billing/PaymentButtonComponent.vue';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import $axios from '@/core/utils/axios-api-config';
// import BillingPaymentMethodComponent from '@/modules/properties-billing/components/BillingPaymentMethodComponent.vue';
import BillingStorageTypeComponent from '@/modules/properties-billing/components/BillingStorageTypeComponent.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { BILLING_STORE } from '@/store/modules/billing';
import { DOCUMENTS_STORE } from '@/store/modules/documents';
import { PROPERTY_STORE } from '@/store/modules/property-details';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';

import { VUE_APP_STRIPE_PUBLISHABLE_KEY } from '../../../../config';

function initializeStripe() {
  let stripe = null;

  if ('Stripe' in window) {
    const { Stripe } = window;

    // eslint-disable-next-line no-new
    stripe = new Stripe(VUE_APP_STRIPE_PUBLISHABLE_KEY);
  }

  return stripe;
}

export default defineComponent({
  name: 'current-members-plan-modal',
  emits: ['close'],
  components: {
    BaseModal,
    // BillingPaymentMethodComponent,
    BillingStorageTypeComponent,
    PaymentButtonComponent,
    NewStorageSummaryBilling,
    CurrentPlanDetails
  },
  data() {
    return {
      email: '',
      emailCount: 10,
      selectedRole: '',
      roles: [
        {
          value: 'viewer',
          label: 'Viewer',
        },
        {
          value: 'editor',
          label: 'Editor',
        },
        {
          value: 'admin',
          label: 'Admin',
        },
      ],

      selectedPlan: null,

      stripe: null,
      sessionId: null,

      redirectUrl: '',

      subscription: {
        monthly: '0.00',
        capacity: '1GB' // default
      },

      property: null
    };
  },
  props: {
    show: Boolean,
  },
  computed: {
    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    ...mapGetters(USERS_STORE, ['user']),

    ...mapGetters(DOCUMENTS_STORE, ['openDocumentBillingModal']),

    ...mapGetters(BILLING_STORE, ['getStoragePlan', 'getTotalMember', 'getPerMemberPrice', 'getListingAmount', 'getPaymentMethod', 'getAddedMember']),

    ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
  },

  watch: {
    activeUserWorkstation: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value?.alias) {
          this.setCurrentSubscriptionPlan();
        }
      }
    }
  },

  async created() {
    const host = `${window.location.protocol}//${window.location.host}`;
    this.redirectUrl = `${host}${this.$route.path}`;

    const { params } = this.$route;
    const propertyId = params.id;

    if (propertyId) {
      this.property = await this.getProperty(propertyId);
    }
    await this.initializeUserState();
    await this.setCurrentSubscriptionPlan();
  },

  methods: {
    ...mapActions(DOCUMENTS_STORE, ['setOpenDocumentBillingModal']),
    ...mapActions(USERS_STORE, ['initializeUserState']),
    ...mapActions(BILLING_STORE, ['setStoragePlan']),
    ...mapActions(PROPERTY_STORE, ['getProperty']),

    onSelectPlan() {
      this.selectedPlan = this.getStoragePlan;
    },

    setCurrentSubscriptionPlan() {
      const { user, activeUserWorkstation } = this;
      const baseUrl = this.$route.path;
      const isUserDocument = (user && baseUrl === '/documents');
      const isUserPropertyDocument = baseUrl.includes('properties');

      if (user && isUserDocument && activeUserWorkstation) {
        const data = JSON.parse(activeUserWorkstation.subscription);

        if (data) {
          const { metadata } = data;
          this.setSubscription(metadata);
        }
      }

      if (this.property && isUserPropertyDocument) {
        const data = JSON.parse(this.property.subscription);

        if (data) {
          const { metadata } = data;
          this.setSubscription(metadata);
        }
      }
    },

    setSubscription(metadata) {
      if (metadata) {
        this.subscription = {
          monthly: metadata.monthly,
          capacity: metadata.capacity
        };

        this.selectedPlan = metadata;
      }
    },

    async onPay() {
      const { selectedPlan } = this;

      if (selectedPlan) {
        await this.initializeStripePayment();
      }
    },

    async initializeStripePayment() {
      this.stripe = initializeStripe();

      if (this.stripe) {
        await this.onCheckout();
      }
    },

    async createSession() {
      const { activeUserWorkstation } = this;
      const { redirectUrl } = this;
      const { id: userId, userStripeCustomer, email } = this.user;
      let customer = { customerId: '' };
      let productName = '';
      let entity = {};

      if (redirectUrl.includes('/documents')) {
        productName = '';
        entity = JSON.stringify({ id: userId, name: 'documents' });
      }

      if (redirectUrl.includes('properties')) {
        const { params } = this.$route;
        const propertyId = params.id;
        entity = JSON.stringify({ id: propertyId, name: 'property' });

        const { id, uprn } = this.property;

        productName = `property-${id}`;

        if (uprn) {
          productName = `${productName}-${uprn}`;
        }
      }

      if (!userStripeCustomer.length) {
        // tmp, needs to be transferred to service
        await $axios.post(`/payment/${userId}/customer`, {
          email,
          entity
        }).then((customerResponse) => {
          customer = customerResponse;
        })
          .catch((e) => {
            this.$notify.error({
              message: e || 'Error occured, please try again'
            });
          });
      } else {
        const [stripeCustomer] = userStripeCustomer; // 1-1 customer only for now
        customer = stripeCustomer;
      }

      const { customerId } = customer;

      if (customerId) {
        const finalPricing = this.getFinalPricing();
        await $axios.post(`/payment/${userId}/session`, {
          customerId,
          price: finalPricing.total,
          redirectUrl: this.redirectUrl,
          productName,
          metadata: JSON.stringify({
            userWorkstationId: activeUserWorkstation.id,
            ...finalPricing,
            totalMember: this.getAddedMember
          })
        }).then(async (sessionResponse) => {
          if (sessionResponse) {
            this.sessionId = sessionResponse.id;
          }
        }).catch(() => {
          this.$notify.error({
            message: 'An error occured. Please try again.'
          });
        })
          .finally(() => {
          });
      }
    },

    getFinalPricing() {
      const { monthly } = this.selectedPlan;
      const vat = monthly * 0.20;
      const priceWithVat = monthly + vat;

      return {
        ...this.selectedPlan,
        monthly,
        vat,
        total: priceWithVat
      };
    },

    async onCheckout() {
      await this.createSession();

      if (this.sessionId) {
        this.stripe.redirectToCheckout({
          sessionId: this.sessionId,
        }).then((result) => {
          if (result.error) {
            this.$notify.error({
              message: result.error || 'Error.'
            });
          } else {
            this.isLoading = false;
          }
        });
      }
    },

    cancel() {
      this.$emit('close');
    },

    confirm() {
      this.$emit('close');
    },
  }
});
</script>
<style lang="scss" scoped>
.default-text {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15000000596046448px;
    margin: 0;
    color: #0C0F4A;
}
.modal-content {
    &__header-title {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    &__row {
        width: 100%;
        padding-bottom: 1rem;
        border-bottom:  2px solid rgba(12, 15, 74, 0.1);
    }
}
</style>
