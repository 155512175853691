<template>
    <div class="document-list-container" v-if="folderList.length !== 0">
      <el-table :data="folderList" style="width: 100%">
        <el-table-column label="Name" :width="mediaRowNameWidth">
          <template #default="scope">
            <div class="name-container flex-row ai-c" @click="onHandleClick(scope.row)">
              <inline-svg class="folder-icon" :src="require('@/assets/icons/recent-files-pdf-icon.svg')" v-if="!scope.row.isDir && scope.row.type === 'application'"/>
              <inline-svg class="folder-icon" :src="require('@/assets/icons/image-icon.svg')" v-if="!scope.row.isDir && scope.row.type === 'image'"/>
              <inline-svg class="folder-icon" :src="require('@/assets/icons/filled-blue-folder-icon.svg')" v-if="scope.row.isDir"/>
              <p class="name-container__name bold-text default-text">{{ scope.row.name }}</p>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="Owner" width="160" v-if="windowWidth > 991">
          <template #default="scope">
            <p class="default-text">{{ scope.row.owner }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Last updated">
          <template #default="scope">
            <p class="default-text">{{ scope.row.updated }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Type" v-if="windowWidth > 991">
          <template #default="scope">
            <p class="default-text">{{ scope.row.files }}</p>
          </template>
        </el-table-column>
        <el-table-column label="Size" width="100">
          <template #default="scope">
            <p class="default-text">{{ scope.row.size }}</p>
          </template>
        </el-table-column>
        <el-table-column label="" width="50">
          <template #default="scope">
            <PopOverSlot :data="data" :document="scope?.row">
              <MoreFilled style="width: 1em; height: 1em;" />
            </PopOverSlot>
          </template>
        </el-table-column>
      </el-table>
    </div>
</template>
<script>
import { MoreFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';

import PopOverSlot from '@/core/components/documents/PopOverSlot.vue';
import appFilters from '@/filters';
import { BILLING_STORE } from '@/store/modules/billing';
import { DOCUMENTS_STORE } from '@/store/modules/documents';
import { USERS_STORE } from '@/store/modules/users';

import { formatBytes } from '../../helpers/file.helper';

export default defineComponent({
  components: {
    PopOverSlot,
    MoreFilled
  },
  props: ['data', 'list'],

  emits: ['on-view-folder', 'download-file'],

  data() {
    return {
      windowWidth: window.innerWidth,

      folderList: [],
    };
  },
  watch: {
    windowWidth(newWidth) {
      this.windowWidth = newWidth;
    },

    list: {
      immediate: true,
      deep: true,
      handler(value) {
        this.folderList = this.normalizeList(value);
      }
    },

    documentList: {
      immediate: true,
      deep: true,
      handler(value) {
        this.folderList = this.normalizeList(value);
      }
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  created() {
    const { list } = this.$props;
    if (list && list.length) {
      this.folderList = this.normalizeList(list);
    }
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user']),
    ...mapGetters(DOCUMENTS_STORE, [
      'folderList',
      'fileList',
      'documentList'
    ]),
  },

  methods: {
    ...mapActions(BILLING_STORE, [
      'setAccumulatedSize',
    ]),

    onToggleIdentificationDialog() {
      this.openIdentificationDialog = !this.openIdentificationDialog;
    },

    onResize() {
      this.windowWidth = window.innerWidth;
    },

    onHandleClick(document) {
      if (document.isDir) {
        this.$emit('on-view-folder', document);
      } else {
        this.$emit('download-file', document);
      }
    },

    normalizeList(folderList) {
      const arrayBytes = [];

      const newList = folderList.map((folder) => {
        const {
          totalFolderByteSize, dateUpdated, fileType, fileSize, isDir
        } = folder;

        let type = '';

        if (isDir) {
          type = 'folder';
        } else {
          type = 'file';
        }

        const isFileTypeList = type === 'file';

        const modifiedDate = appFilters.formatToDate(dateUpdated, 'DD MMM YYYY');
        const byteSize = isFileTypeList ? formatBytes(fileSize) : formatBytes(totalFolderByteSize);

        const subStringFileType = 'application';
        let modifiedFileType = '';

        if (fileType?.includes(subStringFileType)) {
          modifiedFileType = 'application';
        } else {
          modifiedFileType = 'image';
        }

        if (isFileTypeList) {
          arrayBytes.push(fileSize);
        }

        return {
          ...folder,
          owner: 'Me',
          updated: modifiedDate,
          files: isFileTypeList ? fileType : 'folder',
          size: byteSize,
          type: modifiedFileType,
        };
      });

      for (let i = 0; i < folderList.length; i++) {
        let type = '';
        if (folderList[i].isDir) {
          type = 'folder';
        } else {
          type = 'file';
        }
        const isFileTypeList = type === 'file';

        if (isFileTypeList) {
          const sum = arrayBytes.reduce((accumulator, a) => {
            return accumulator + a;
          }, 0);

          this.setAccumulatedSize(sum);
        }
      }

      return newList;
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.bold-text {
    font-weight: 700;
}
.name-container {
  width: 90%;
  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 1.2em;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}
.el-table {
  --el-table-tr-bg-color: transparent;
  --el-table-bg-color: transparent;
  --el-table-header-bg-color: transparent;
}
:deep(.el-table th.el-table__cell.is-leaf) {
  border-bottom: none;
}
:deep(.el-table__row) {
  cursor: pointer;
}
:deep(.cell) {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #0c0f4a;
  font-size: 14px;
  font-weight: 700;
  padding: 0;
  font-family: Mulish;
}

@include media-xs-max-width() {
  .default-text {
    font-size: 12px;
  }
  :deep(.cell) {
    font-size: 12px;
  }
}
</style>
