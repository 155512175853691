<template>
    <div class="document-empty-state flex-column jc-c ai-c">
        <div class="document-empty-state__wrapper flex-column jc-c ai-c">
           <inline-svg :src="require(`@/assets/icons/empty-files-folder-icon.svg`)"></inline-svg>
           <p class="document-empty-state__text default-text">Create a new folder or upload<br> files to get started</p>
           <PopOverSlot :data="popOverHeaderData" placement="top">
             <el-button type="primary" class="document-empty-state__button flex-row ai-c jc-c" round>New</el-button>
           </PopOverSlot>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import PopOverSlot from '@/core/components/documents/PopOverSlot.vue';

export default defineComponent({
  components: {
    PopOverSlot
  },
  data() {
    return {
      /* eslint-disable global-require */
      popOverHeaderData: [
        {
          icon: `${require('@/assets/icons/upload-icon.svg')}`,
          name: 'File upload',
          event: 'openFileUploadModal',
        },
        // {
        //   icon: `${require('@/assets/icons/filled-gray-folder-icon.svg')}`,
        //   name: 'Folder upload',
        //   event: 'folderUpload',
        // },
        {
          icon: `${require('@/assets/icons/create-new-folder-icon.svg')}`,
          name: 'New folder',
          event: 'openModalNewFolder',
        },
      ],
      /* eslint-disable global-require */
    };
  },
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.document-empty-state {
    height: 100%;
    width: 100%;

   &__wrapper {
       height: 100%;
       width: 100%;
       margin-top: -100px;
   }
   &__text {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.4000000059604645px;
        text-align: center;
        color: rgba(12, 15, 74, 0.5);
   }
   &__button {
       height: 42px;
       background: #FFFFFF;
       box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.1);
       border-radius: 8px;
       color: #4F55F0;
       padding: 0 4rem;
       box-sizing: border-box;
       border: none;
   }
}

@include media-md-max-width() {
    .document-empty-state {
        &__text {
            font-size: 15px;
            line-height: 20px;
        }
        &__button {
            height: 40px;
            padding: 0 4rem;
        }
    }
}
</style>
