<template>
    <div class="new-summary-billing flex-column">
        <BillingDetailItem labelText="New storage plan" :amount="priceSummary?.monthly"/>
        <el-divider />
        <BillingDetailItem labelText="Sub total" :amount="priceSummary?.monthly"/>
        <BillingDetailItem labelText="VAT (20%)" :amount="priceSummary?.vat"/>
        <BillingDetailItem labelText="TOTAL" labelSpanText="( billed monthly )" :labelSize="LABEL_SIZE_XLARGE" amountTextSize="18px" :amount="priceSummary?.total"/>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import BillingDetailItem from '@/core/components/billing/BillingDetailItem.vue';
import { LABEL_SIZE_SMALL, LABEL_SIZE_XLARGE } from '@/core/constants/label';
import { BILLING_STORE } from '@/store/modules/billing';

export default defineComponent({

  props: [
    'plan'
  ],

  components: {
    BillingDetailItem,
  },

  data() {
    return {
      defaultValue: '£0.00',
      priceSummary: {
        total: '0.00'
      },
      LABEL_SIZE_SMALL,
      LABEL_SIZE_XLARGE
    };
  },

  watch: {
    getStoragePlan: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.setSelectedPlan(value);
        }
      }
    }
  },

  computed: {
    ...mapGetters(BILLING_STORE, ['getStoragePlan']),
  },

  methods: {
    setSelectedPlan(value) {
      const { monthly } = value;
      const vat = monthly * 0.20;
      const priceWithVat = monthly + vat;

      this.priceSummary = {
        monthly,
        vat,
        total: priceWithVat
      };
    },
  },
});
</script>
<style lang="scss" scoped>
.new-summary-billing {
  margin-top: 2rem;
}
.el-divider {
    margin: 0;
    height: 2px;
}
</style>
