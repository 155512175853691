<template>
<BaseModal
  :show="show"
  :showCancelBtn="false"
  :showSubmitBtn="false"
  :showDefaultCloseBtn="false"
  @cancel="cancel"
  :hasActions="false"
  customClass="small-modal"
>
  <div class="modal-content flex-column jc-sb">
    <div class="flex-column jc-sb">
        <div class="modal-content__modal-header flex-row jc-sb ai-c">
            <h2 class="modal-content__header-title default-text">Move to</h2>
            <div class="modal-content__header-icon">
              <inline-svg @click="cancel" :src="require(`@/assets/icons/x.svg`)"></inline-svg>
            </div>
        </div>
        <div class="modal-content__content flex-column">
          <div class="modal-content__content-list">
             <el-scrollbar>
                <div class="modal-content__scrollbar-content flex-column">
                  <el-tree
                    :data="currentFolderList"
                    node-key="id"
                    :props="defaultProps"
                    @node-click="setActiveIndex"
                  >
                    <template #default="{ node, data }">
                      <span class="modal-content__item flex-row ai-c"  :class="{'is-active': data.id === activeIndex}">
                          <inline-svg class="folder-icon" :src="require('@/assets/icons/filled-blue-folder-icon.svg')"/>
                          <p class="modal-content__item-name default-text">{{ node.label }}</p>
                      </span>
                    </template>
                  </el-tree>
                </div>
             </el-scrollbar>
          </div>
          <el-divider/>
          <div class="modal-content__cta-container flex-row ai-c">
             <div class="modal-content__cta-wrapper flex-row ai-c" v-if="!showInputCreateFolder" @click="showInputCreateFolder = true">
                <inline-svg class="folder-icon" :src="require('@/assets/icons/create-new-folder-icon.svg')"/>
                <p class="modal-content__cta-text default-text">Create new folder</p>
              </div>
              <div class="modal-content__input-wrapper grid" v-else>
                 <el-input v-model="folderName" placeholder="Enter folder name"/>
                 <div class="flex-row ai-c jc-fe">
                   <el-icon @click="create" style="cursor: pointer;" :size="40" color="#198754"><CircleCheckFilled/></el-icon>
                   <el-icon @click="showInputCreateFolder = false" style="cursor: pointer;" :size="40" color="#d11a2a"><CircleCloseFilled/></el-icon>
                 </div>
              </div>
          </div>
        </div>
    </div>
    <div class="modal-content__actions flex-row">
      <el-button @click="onMove" class="button" :class="{'active': activeIndex !== null}">MOVE HERE</el-button>
    </div>
  </div>
</BaseModal>
</template>
<script>
import { CircleCheckFilled, CircleCloseFilled } from '@element-plus/icons';
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import BaseModal from '@/core/components/ui/BaseModal.vue';
import { AUTHENTICATION_STORE } from '@/store/modules/authentication';
import { DOCUMENTS_STORE } from '@/store/modules/documents';

// interface Tree {
//   label: string
//   children?: Tree[]
// }

export default defineComponent({
  name: 'current-members-plan-modal',
  emits: ['close'],
  components: {
    BaseModal,
    CircleCloseFilled,
    CircleCheckFilled
  },
  data() {
    return {
      folderCount: 10,
      activeIndex: null,
      currentFolderList: [],

      folderTarget: {},
      isMoving: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      folderName: '',
      showInputCreateFolder: false,
    };
  },
  props: {
    show: Boolean,
  },

  watch: {
    selectedDocument: {
      immediate: true,
      async handler(value) {
        if (value) {
          await this.getFolderList();
        }
      }
    }
  },

  computed: {
    ...mapGetters(AUTHENTICATION_STORE, ['authenticatedUser']),
    ...mapGetters(DOCUMENTS_STORE, [
      'openMoveModal',
      'folderList',
      'selectedDocument',
      'openedFolder',
      'fileList',
      'documentList'
    ]),
  },

  methods: {
    ...mapActions(DOCUMENTS_STORE, [
      'setOpenMoveModal',
      'createUserDocument',
      'getUserDocuments',
      'moveUserDocuments',
      'setFolderList',
      'setDocumentList'
    ]),

    setActiveIndex(folder) {
      this.activeIndex = folder.id;

      this.folderTarget = folder;
    },

    cancel() {
      this.activeIndex = null;

      if (!this.isMoving) {
        this.$emit('close');
      }
    },

    async onMove() {
      const { folderTarget, selectedDocument } = this;
      const { userId } = this.authenticatedUser;
      const { parentId } = folderTarget;
      const hasParentParam = parentId || null;
      const params = {
        userId,
        formData: {
          parentId: folderTarget.id,

          // TD: should support mulitple
          documents: [{
            id: selectedDocument.id
          }]
        }
      };

      this.isMoving = true;

      await this.moveUserDocuments(params)
        .then(async () => {
          await this.getUserDocuments(params)
            .then((userDocuments) => {
              const list = userDocuments.filter((userDocument) => userDocument.isDir === true
                && userDocument.parentId === hasParentParam);

              this.setFolderList(list);
              this.setDocumentList([...list, ...this.fileList]);
            });
        })
        .catch(() => {
          this.$notify.error({
            message: 'Error creating folder.'
          });
        })
        .finally(() => {
          this.isMoving = false;
          this.cancel();
        });

      // this.activeIndex = null;
      // this.$emit('close');
    },

    async create() {
      const { id } = this.openedFolder;
      const parentId = id || null;
      const { userId } = this.authenticatedUser;
      const params = {
        userId,
        formData: {
          name: this.folderName,
          parentId
        }
      };
      let docParams = {};
      if ('id' in this.openedFolder) {
        docParams = { parentId: id, isDir: 1 };
      }

      await this.createUserDocument(params)
        .then(async () => {
          await this.getFolderListForCreateFolder(docParams);
        })
        .catch(() => {
          this.$notify.error({
            message: 'Error creating folder.'
          });
        })
        .finally(() => {
          this.folderName = '';
          this.showInputCreateFolder = false;
          this.$emit('close');
        });
    },

    async getFolderListForCreateFolder(options = {}) {
      const { userId } = this.authenticatedUser;
      const params = { userId };
      const { parentId } = options;
      const hasParentParam = parentId || null;

      if (Object.keys(options).length) {
        params.options = options;
      }

      await this.getUserDocuments(params)
        .then((userDocuments) => {
          const list = userDocuments.filter((userDocument) => userDocument.isDir === true
            && userDocument.parentId === hasParentParam);

          this.setFolderList(list);
          this.setDocumentList([...list, ...this.fileList]);
        });
    },

    async getFolderList() {
      const { selectedDocument } = this;
      const { userId } = this.authenticatedUser;
      const params = { userId, options: { folderTree: 1 } };

      await this.getUserDocuments(params)
        .then((userDocuments) => {
          const list = userDocuments.filter((userDocument) => userDocument.id !== selectedDocument.id);
          this.currentFolderList = this.listToTree(list);
        });
    },

    listToTree(list) {
      const map = {}; let node; const roots = []; let
        i;

      for (i = 0; i < list.length; i += 1) {
        map[list[i].id] = i;
        // eslint-disable-next-line no-param-reassign
        list[i].children = [];
      }

      for (i = 0; i < list.length; i += 1) {
        node = list[i];
        if (node.parentId) {
          const ref = list[map[node.parentId]];
          if (ref && 'children' in ref) {
            ref.children.push(node);
          }
        } else {
          roots.push(node);
        }
      }

      return roots;
    },
  }
});
</script>
<style lang="scss" scoped>
.default-text {
    font-family: Mulish;
    font-size: 18px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.15000000596046448px;
    margin: 0;
    color: #0C0F4A;
}
.modal-content {
    &__header-title {
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.25px;
    }
    &__actions {
      width: 100%;
    }
    &__cta-wrapper {
        width: 200px;
        cursor:pointer;
        margin: 1rem 0;
    }
    &__item {
        cursor: pointer;
        // padding: .7rem;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        &:hover {
          // background: rgba(255, 165, 0, 0.1);
            .modal-content__item-name {
                color: #FFA500;
            }
        }
    }
    &__item.is-active {
      // background: rgba(255, 165, 0, 0.1);
        .modal-content__item-name {
            color: #FFA500;
        }
    }
    &__item-name {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.25px;
    }
    &__cta-text {
        font-size: 14px;
        font-weight: 600;
    }
    &__content-list {
        height:240px;
        margin-bottom: .7rem;
    }
    &__scrollbar-content {
        padding: 0 .7rem .5rem 0;
        gap: .3rem;
    }
    &__input-wrapper {
      width: 100%;
      grid-template-columns: 3fr 1fr;
    }
}
:deep(.el-scrollbar__bar) {
    display: block !important;
}
:deep(.el-scrollbar__bar.is-vertical) {
    top: 0;
    right: 0;
    width: 6px !important;
}
.el-divider {
    margin: 0;
}
:deep(.el-tree-node__content) {
  height: 42px;
  border-radius: 8px;
}
:deep(.el-tree-node__content:hover) {
  background: rgba(255, 165, 0, .2);
}
:deep(.el-input__inner) {
  border-radius: 50px;
  border: none;
  background: rgba(12, 15, 74, 0.05);
  border: none;
  color: #111;
  border-radius: 30px;
  font-size: 14px;
  font-family: Mulish;
}
.button {
    height: 40px;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
    background-color: rgba(12, 15, 74, 0.05);
    border: none;
    border-radius: 7px;
    @extend .default-text;
    font-size: 15px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    color: rgba(12, 15, 74, 0.5);

    &:hover {
        background-color: rgba(12, 15, 74, 0.05);
        color: rgba(12, 15, 74, 0.5);
    }
}
.active {
    background-color: #FFA500;
    color: #fff;

    &:hover {
        background-color: #FFA500;
        color: #fff;
    }
}
</style>
