<template>
   <PopOverSlot :data="data" v-if="disableBtn">
    <div class="add-cta flex-row ai-c">
        <el-icon class="flex-row ai-c jc-c" :size="20" color="#FFF"><Plus/></el-icon>
        <p class="add-cta__label hide-in-small-mobile">New</p>
    </div>
    </PopOverSlot>
</template>
<script>
import { Plus } from '@element-plus/icons';
import { defineComponent } from 'vue';

import PopOverSlot from '@/core/components/documents/PopOverSlot.vue';

export default defineComponent({
  components: {
    Plus,
    PopOverSlot
  },
  props: ['data', 'disableBtn'],
});
</script>
<style lang="scss" scoped>
.add-cta {
    gap: .5rem;
    cursor: pointer;
    .el-icon {
        background: #FAA100;
        border-radius: 100%;
        width: 30px;
        height: 30px;
        box-sizing: border-box;
    }
    &__label {
        font-family: Mulish;
        font-size: 18px;
        font-weight: 700;
        line-height: 22px;
        letter-spacing: 0.25px;
        text-align: left;
        margin: 0;
        color: #0C0F4A;
    }
}
</style>
