<template>
    <div class="storage-plan flex-row jc-sb ai-c" @click="showModal()">
        <div class="storage-plan__col-one flex-row ai-c">
           <div class="storage-plan__details flex-column">
              <p class="storage-plan__storage default-text">Storage plan - <span>{{ subscription.capacity }}</span></p>
              <p class="storage-plan__amount default-text">
                {{ subscription.monthly ? $filters.formatToCurrency(subscription.monthly) : DEFAULT_VALUE }} <span>monthly</span></p>
           </div>
           <div class="storage-plan__progress-container">
                <el-progress type="circle" :percentage="usedStoragePercentage" :stroke-width="10">
                    <template #default>
                      <div class="storage-plan__data-wrapper flex-column">
                        <span class="percentage-value"><span v-if="availableStorageSize">{{ availableStorageSize }}</span>GB</span>
                        <span class="percentage-label">Available</span>
                      </div>
                    </template>
                </el-progress>
            </div>
        </div>
        <div class="storage-plan__col-two flex-row ai-c" v-if="!isHosted">
            <p class="storage-plan__cta-text default-text hide-in-small-mobile">Manage</p>
            <div class="storage-plan__arrow flex-row ai-c jc-c">
                <el-icon :size="15" color="#FFF"><ArrowRightBold/></el-icon>
            </div>
        </div>
    </div>
</template>
<script>
import { ArrowRightBold } from '@element-plus/icons';
import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';

import {
  DEFAULT_VALUE
} from '@/core/components/billing/constants';
import { BILLING_STORE } from '@/store/modules/billing';
import { DOCUMENTS_STORE } from '@/store/modules/documents';
import { USER_WORKSTATION } from '@/store/modules/user-workstation';
import { USERS_STORE } from '@/store/modules/users';

import {
  formatBytesToGB,
  getMBPercentage
} from '../../helpers/file.helper';

export default defineComponent({
  components: {
    ArrowRightBold
  },

  props: ['property'],

  data() {
    return {
      subscription: {
        monthly: '0.00',
        capacity: '1GB' // default
      },

      availableStorageSize: '',

      usedStoragePercentage: 0,

      DEFAULT_VALUE
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['user', 'isHosted']),
    ...mapGetters(DOCUMENTS_STORE, [
      'openDocumentBillingModal',
    ]),

    ...mapGetters(BILLING_STORE, ['getStoragePlan', 'accumulatedSize']),

    ...mapGetters(USER_WORKSTATION, ['activeUserWorkstation']),
  },

  watch: {
    user: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.setStoragePlan();
        }
      }
    },
    property: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.setStoragePlan();
        }
      }
    },
    activeUserWorkstation: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value?.alias) {
          this.setStoragePlan();
        }
      }
    },

    accumulatedSize: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.availableStorageSize = this.calculateStorageSize(value);
        }
      }
    },
  },

  created() {
    this.setStoragePlan();
    this.setAccumulatedSize('');
  },

  methods: {
    ...mapActions(DOCUMENTS_STORE, [
      'setOpenDocumentBillingModal',
    ]),
    ...mapActions(USER_WORKSTATION, ['activateWorkstation']),

    ...mapActions(BILLING_STORE, [
      'setAccumulatedSize',
    ]),

    showModal() {
      if (!this.isHosted) {
        this.setOpenDocumentBillingModal(true);
      }
    },

    setStoragePlan() {
      const { user, activeUserWorkstation } = this;
      const baseUrl = this.$route.path;
      const isUserDocument = (user && baseUrl === '/documents');
      const isUserPropertyDocument = baseUrl.includes('properties');

      if (user && isUserDocument && activeUserWorkstation) {
        const data = JSON.parse(activeUserWorkstation.subscription);

        if (data) {
          const { metadata } = data;
          this.setSubscription(metadata);
        }
      }

      if (this.property && isUserPropertyDocument) {
        const data = JSON.parse(this.property.subscription);

        if (data) {
          const { metadata } = data;
          this.setSubscription(metadata);
        }
      }
    },

    setSubscription(metadata) {
      if (metadata) {
        this.subscription = {
          monthly: metadata.monthly,
          capacity: metadata.capacity
        };
      }

      if (!this.availableStorageSize) {
        this.availableStorageSize = parseInt(metadata.capacity, 10);
      }
    },

    calculateStorageSize(sum) {
      const { subscription } = this;
      const { capacity } = subscription;
      const intCapacity = parseInt(capacity, 10);
      let totalInString = intCapacity;

      if (sum) {
        totalInString = formatBytesToGB(sum, intCapacity);
        this.usedStoragePercentage = getMBPercentage(sum, intCapacity);
      }

      return totalInString;
    },
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.default-text {
    font-family: Mulish;
    font-size: 16px;
    font-weight: 700;
    line-height: 0;
    letter-spacing: 0.25px;
    text-align: left;
    margin: 0;
    color: #0C0F4A;
}
.storage-plan {
    height: 118px;
    box-sizing: border-box;
    padding: 2.5rem 2rem;
    border-radius: 8px;
    border: 2px solid transparent;
    box-shadow: 0px 4px 20px rgba(42, 42, 42, 0.05);
    background: #FFFFFF;
    cursor: pointer;

    &:hover {
        border-color:#FFA500;
        transition: all .3s ease-in-out;
    }

    &__col-one {
        gap: 3rem;
    }

    &__storage {
        font-size: 18px;
        line-height: 15px;
        span {
            font-weight: 700;
            color: #FFA500;
        }
    }
    &__amount {
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15000000596046448px;

        span {
            font-size: 16px;
            font-weight: 600;
            letter-spacing: 0.4000000059604645px;
            color: rgba(12, 15, 74, 0.5);
        }
    }

    &__data-wrapper {
      gap: 0;
    }

    &__progress-container {
        .el-progress {
            :deep(.el-progress-circle) {
                width: 80px !important;
                height: 80px !important;
            }
        }
        .percentage-value {
            display: block;
            font-size: 12px;
            font-weight: 800;
            line-height: 25px;
            letter-spacing: 0.25px;
            text-align: center;
            color: #4F55F0;

            span {
                font-size: 20px;
                font-weight: 800;
                line-height: 25px;
                letter-spacing: 0.25px;
                text-align: center;
            }
        }
        .percentage-label {
            display: block;
            font-size: 10px;
            font-weight: 400;
            // line-height: 25px;
            letter-spacing: 0.25px;
            text-align: center;
            color:rgba(12, 15, 74, 0.5);
        }
    }

    &__cta-text {
        font-size: 18px;
        font-weight: 700;
        text-align: right;
        color: #FFA500;
    }

    &__arrow {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background: #FFA500;
    }
}
@include media-xs-max-width() {
    .storage-plan {
        padding: 2rem 1.5rem;
        gap: 1.5rem;
        &__col-one {
            width: 100%;
            gap: 1rem;
            justify-content: space-between;
        }
        &__details {
            gap: .7rem;
        }
        &__storage {
            font-size: 14px;
            line-height: 14px;
        }
        &__amount {
            font-size: 16px;
            line-height: 14px;
            span {
                font-size: 14px;
            }
        }

        &__data-wrapper {
          gap: .5rem;
        }

        &__progress-container {
            .el-progress {
                :deep(.el-progress-circle) {
                    width: 70px !important;
                    height: 70px !important;
                }
            }
            .percentage-value {
                font-size: 10px;
                line-height: 0;
                span {
                    font-size: 16px;
                }
            }
            .percentage-label {
                font-size: 8px;
            }
        }

    }
}
</style>
