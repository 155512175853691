import { defineComponent } from '@vue/runtime-core';
import { mapActions, mapGetters } from 'vuex';
import BaseModal from '@/core/components/ui/BaseModal.vue';
import { DOCUMENTS_STORE } from '@/store/modules/documents';
export default defineComponent({
    name: 'current-members-plan-modal',
    emits: ['close'],
    components: {
        BaseModal,
    },
    data() {
        return {
            email: '',
            emailCount: 10,
            selectedRole: '',
            roles: [
                {
                    value: 'viewer',
                    label: 'Viewer',
                },
                {
                    value: 'editor',
                    label: 'Editor',
                },
                {
                    value: 'admin',
                    label: 'Admin',
                },
            ]
        };
    },
    props: {
        show: Boolean,
    },
    computed: {
        ...mapGetters(DOCUMENTS_STORE, ['openShareModal']),
    },
    methods: {
        ...mapActions(DOCUMENTS_STORE, ['setOpenShareModal']),
        cancel() {
            this.$emit('close');
        },
        confirm() {
            this.$emit('close');
        },
    }
});
