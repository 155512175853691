<template>
    <div class="documents-layout flex-column">
        <section class="documents-layout__header flex-row jc-sb ai-c" v-show="viewType === 'list'">
          <div class="flex-row ai-c" v-if="breadCrumbLinks.length < 4">
            <div class="flex-row ai-c cursor-pointer"
              v-for="(item, index) in breadCrumbLinks" :key="index"
              @click="onViewFolder(item)">
              <span class="documents-layout__title default-text" >
                {{ item.name }}
              </span>
              <el-icon
                v-if="isLastIndex(breadCrumbLinks, index)"
                :size="16" color="rgba(12, 15, 74, 0.40)">
                <ArrowRightBold/>
              </el-icon>
            </div>
          </div>
          <div class="flex-row ai-c" v-else>
            <PopOverSlot
              :data="popOverData"
              @handle-click="setViewFolder($event)">
              <el-icon :size="20"><MoreFilled/></el-icon>
            </PopOverSlot>
            <div class="flex-row ai-c">
              <div class="flex-row ai-c cursor-pointer" v-for="(item, index) in modifiedbreadCrumbLinks" :key="index" @click="onViewFolder(item)">
                <span class="documents-layout__title default-text" >
                  {{ item.name }}
                </span>
                <el-icon v-if="isLastIndex(modifiedbreadCrumbLinks, index)" :size="16" color="rgba(12, 15, 74, 0.40)"><ArrowRightBold/></el-icon>
              </div>
            </div>
          </div>
        </section>
        <section class="documents-layout__contents"
          v-show="viewType === 'list'"
          :class="[viewType === 'tile' ? 'grid' : 'flex-column']">
            <slot></slot>
        </section>
    </div>
</template>
<script>
import { ArrowRightBold, MoreFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';

import PopOverSlot from '@/core/components/documents/PopOverSlot.vue';
import { HEADER_DOCUMENTS_LIST } from '@/core/constants/header';

export default defineComponent({
  components: {
    ArrowRightBold,
    MoreFilled,
    PopOverSlot
  },

  props: ['title', 'viewType', 'titlePath'],

  emits: ['on-view-folder'],

  data() {
    return {
      HEADER_DOCUMENTS_LIST,
      breadCrumbLinks: [],

      dropDownLinks: [],
      modifiedbreadCrumbLinks: [],

      popOverData: [],
    };
  },

  watch: {
    titlePath: {
      immediate: true,
      deep: true,
      handler(value) {
        this.breadCrumbLinks = value;
        this.modifyBreadCrumbLinks();
      }
    },
  },

  created() {
    this.breadCrumbLinks = this.$props.titlePath;
    this.modifyBreadCrumbLinks();
  },

  methods: {
    onViewFolder(folder) {
      this.$emit('on-view-folder', folder);
    },

    /* eslint-disable global-require */
    modifyBreadCrumbLinks() {
      if (this.breadCrumbLinks.length > 3) {
        this.dropDownLinks = this.breadCrumbLinks.slice(0, -2);

        this.modifiedbreadCrumbLinks = this.breadCrumbLinks.slice(-2);

        this.popOverData = this.dropDownLinks.map((link) => ({
          id: link?.id,
          name: link?.name,
          icon: `${require('@/assets/icons/filled-blue-folder-icon.svg')}`
        }));

        if (this.popOverData.length > 0) {
          this.popOverData[0] = { name: HEADER_DOCUMENTS_LIST, icon: `${require('@/assets/icons/filled-blue-folder-icon.svg')}` };
        }
      }
    },
    /* eslint-disable global-require */

    setViewFolder(id) {
      let folder = {};

      if (id === HEADER_DOCUMENTS_LIST) {
        folder = {}; // Set folder to an empty object
      } else {
        folder = { id };
      }

      this.onViewFolder(folder);
    },

    isLastIndex(array, index) {
      return array.length > 1 && array[index + 1];
    }
  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.documents-layout {
  &__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.25px;
    text-align: left;
    position: relative;
    z-index: 0;
  }
  &__contents {
    padding: .3rem .5rem;
    gap: 1.2rem;
    grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  }
  &__list-header-container {
    grid-template-columns: 2.5fr .9fr .9fr .9fr .5fr;
  }
  &__list-header {
    font-size: 14px;
  }
  &__breadcrumb-link {
  }
}
.grid {
   padding: .3rem .5rem;
}
.flex-column {
    padding: 0;
}

@include media-xs-max-width() {
  .grid {
    padding: .3rem 0.2rem 0.3rem 0.5rem;
  }
}
</style>
