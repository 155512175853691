<template>
    <div class="documents-header sticky flex-row ai-c jc-sb">
        <SearchInputComponent/>
        <SearchInputMobile/>
        <div class="documents-header__col flex-row ai-c">
           <FilterComponent @set-view="setView" :viewType="viewType"/>
           <AddActionWithPopover :data="data" :disableBtn="canCreate"/>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import FilterComponent from '@/core/components/common/header/FilterComponent.vue';
import SearchInputComponent from '@/core/components/common/header/SearchInputComponent.vue';
import AddActionWithPopover from '@/core/components/documents/AddActionWithPopover.vue';
import SearchInputMobile from '@/core/components/documents/SearchInputMobile.vue';
import { USERS_STORE } from '@/store/modules/users';

export default defineComponent({
  components: {
    SearchInputComponent,
    FilterComponent,
    AddActionWithPopover,
    SearchInputMobile
  },
  props: ['data', 'viewType'],
  emits: ['set-view'],
  setup(props, { emit }) {
    function setView(viewType) {
      emit('set-view', viewType);
    }

    return {
      setView,
    };
  },

  computed: {
    ...mapGetters(USERS_STORE, ['wsRole', 'isWsAdmin']),

    canCreate() {
      const { wsRole, isWsAdmin } = this;
      let show = true;

      if (wsRole && !isWsAdmin) {
        show = !show;
      }

      return show;
    },

  }
});
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/mixins/" as *;

.documents-header {
    gap: 2rem;
    width: 100%;
    z-index:3;
    background: #F1F6FB;
    // padding-bottom: 1rem;
    &__col {
     gap: 1.5rem;
    }
}
@include media-md-max-width() {
  .documents-header {
    // flex-direction: column;
    gap: .8rem;
    &__col {
      width: 100%;
      justify-content: flex-end;
    }
  }
}
@include media-xs-max-width() {
  .documents-header {
    &__col {
      width: auto;
    }
  }
}
</style>
