<template>
    <div class="search-header-mobile">
        <el-input
            class="search-input w-50 m-2"
            placeholder="Search"
            v-model="input"
            :disabled=true
        >
            <template #prefix>
                <el-icon class="el-input__icon"><Search /></el-icon>
            </template>
        </el-input>
    </div>
</template>
<script>
import { Search } from '@element-plus/icons';

export default {
  components: {
    Search
  },
};
</script>
<style lang="scss" scoped>
@use "../../../assets/scss/mixins/media-query" as *;
@use "../../../assets/scss/variables/layout" as *;

.search-header-mobile {
    display: none;
    width: 100%;
    .el-input {
        width: 100%;
        height: 42px;
        :deep(.el-input__inner) {
            background: rgba(12, 15, 74, 0.05);
            border: none;
            color: #111;
            border-radius: 30px;
            font-size: 14px;
            padding: 20px 20px 20px 40px;
            font-family: Arial;
        }

        :deep(.el-input__prefix) {
            left:10px;
            color:#111;
        }
    }
}
@include media-md-max-width() {
    .search-header-mobile {
        display:flex;
    }
}
</style>
